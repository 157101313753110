import * as React from "react"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet'

import siteSettings from "../constants/site-settings";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";

import '../styles/404.scss'


const NotFoundPage = ({data}) => {

  const fluid404ImgSource = [
    data.image.childImageSharp.fluid,
  ];

  const metaTitle = `Not Found | ${siteSettings.siteTitle}`

  return (
    <div className="w-full">
      <Helmet>
        <title>{metaTitle}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"/>
        <meta name="description" content={siteSettings.siteDescription}/>

        <meta http-equiv="refresh" content="0;url=https://www.gdbgaragedoorservice.com/"/>

        <meta property="og:site_name" content={metaTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={siteSettings.siteTitle}/>
        <meta property="og:description" content={siteSettings.siteDescription}/>
        <meta property="og:url" content={siteSettings.siteUrl}/>
        <meta property="og:type" content="Landing page"/>

        <meta property="og:image" content={siteSettings.image}/>
        <meta property="og:image:width" content={siteSettings.shareImageWidth}/>
        <meta property="og:image:height" content={siteSettings.shareImageHeight}/>

        <meta name="twitter:title" content={metaTitle}/>
        <meta name="twitter:description" content={siteSettings.siteDescription}/>
        <meta name="twitter:url" content={siteSettings.siteUrl}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={siteSettings.image}/>

        <link rel="canonical" href={siteSettings.siteUrl}/>
      </Helmet>

      <HeaderComponent/>
      <main className="relative not-found">
        <div className="block w-full h-full absolute top-0 left-0 z-0">
          <Img fluid={fluid404ImgSource} objectFit="cover" className="h-full"/>
        </div>
        <div className="flex w-full h-full absolute top-0 left-0 z-1 px-5 justify-center">
          <div className="flex flex-col gap-5 justify-center items-center description">
            <h1>404</h1>
            <p>The page is not found</p>
            <Link to="/" className="gdb-btn primary home-btn flex">
              Go to the main page
            </Link>
          </div>
        </div>
      </main>
      <FooterComponent/>
    </div>
  )
}

export default NotFoundPage

export const query = graphql`
    query {
        image: file(relativePath: { eq: "404.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
